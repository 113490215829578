@use '@angular/material' as mat;

@mixin usage-event-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .usage-event-table-container {
    @include csv-export-button-theme($theme);

    .mat-table {

      .mat-column-totalDistance,
      .mat-column-fuelEfficiency,
      .mat-column-totalFuel{
        justify-content: right;
        span {
          margin-right: 1.125rem;
        }
        .mat-sort-header-content {
          text-align: right;
        }
      }

      .mat-column-endOdometer{
        justify-content: right;
        span {
          margin-right: .5rem;
        }
        .mat-sort-header-content {
          text-align: right;
        }
      }

      .mat-column-totalEngineHour{
        max-width: 6rem;
        text-align: center;
      }
      .mat-column-vin{
        min-width: 8.5rem;
      }
      .mat-column-driverName {
        width: 8.625rem;
      }
    }
  }
}
