@use '@angular/material' as mat;

@import 'src/app/components/settings/settings.theme';

@mixin unused-assets-container-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .unused-assets-container {
    @include card-theme($theme);
    @include dialog-theme($theme);
    @include mobile-cards-theme($theme);

    @include settings-theme($theme);

    .header-container {
      padding-bottom: 1.188rem;
    }

    zui-asset-name {
      float: right; 
      .asset-name .asset-icon {
        width: unset;
      }
    }
  }
  .back-button-area {
    background-color: mat.get-color-from-palette($background, flat-button);
    height: 3.4rem;
    @include mat.typography-level($zonar-default-typography, button);
    font-size: 1.25rem;
    span,
    mat-icon {
      color: mat.get-color-from-palette($zonar-gray, 100);
    }
    
    mat-icon {
      padding-right: 0.25rem;
      padding-left: 1.75rem;
    }
  }
}

