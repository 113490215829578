@use '@angular/material' as mat;

@import 'src/app/components/usage-table/usage-table.theme';
@import 'src/app/components/usage-event-table/usage-event-table.theme';
@import 'src/app/components/card/card.theme';
@import 'src/app/components/dialog/dialog.theme';
@import 'src/app/components/mobile-cards/mobile-cards.theme';
@import 'src/app/components/idle-table/idle-table.theme';
@import 'src/app/components/idle-event-table/idle-event-table.theme';
@import 'src/app/components/summary/summary.theme';
@import 'src/app/components/idle-summary/idle-summary.theme';
@import 'src/app/components/top-idlers/top-idlers.component.theme';
@import 'src/app/components/map-dialog/map-dialog.theme';
@import 'src/app/components/dialog/utilization-info-dialog/utilization-info-dialog.theme';
@import 'src/app/components/settings/settings.theme';
@import 'src/app/containers/idle-container/idle-container.theme';
@import 'src/app/containers/idle-event-container/idle-event-container.theme';
@import 'src/app/components/utilization-summary/utilization-summary.theme';
@import 'src/app/components/utilization-table/utilization-table.theme';
@import 'src/app/components/utilization-pie-chart/utilization-pie-chart.theme';
@import 'src/app/components/mobile-utilization-cards/mobile-utilization-cards.theme';
@import 'src/app/components/utilization-trend-chart/utilization-trend-chart.theme';
@import 'src/app/components/asset-driver-info/asset-driver-info.theme';

@mixin overview-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include map-dialog-theme($theme);
  @include utilization-info-dialog-theme($theme);

  .overview-container {
    @include card-theme($theme);
    @include dialog-theme($theme);
    @include mobile-cards-theme($theme);
    @include usage-table-theme($theme);
    @include usage-event-table-theme($theme);
    @include idle-container-theme($theme);
    @include idle-event-container-theme($theme);
    @include idle-table-theme($theme);
    @include idle-event-table-theme($theme);
    @include summary-theme($theme);
    @include idle-summary-theme($theme);
    @include top-idlers-theme($theme);
    @include settings-theme($theme);
    @include utilization-summary-theme($theme);
    @include utilization-table-theme($theme);
    @include utilization-pie-chart-theme($theme);
    @include utilization-mobile-cards-theme($theme);
    @include utilization-trend-chart-theme($theme);
    @include asset-driver-info-theme($theme);
    
    a {
      color: mat.get-color-from-palette($accent);
      text-decoration: underline;
    }
    
    .data-viz-wrapper {
      margin-bottom: 1rem;

      .data-viz-child {
        .mat-card {
          margin: 0;
          // height: 100%;
        }
      }
    }

    .skeleton-bar {
      padding-left: 3%;
    }
    .mat-tab-nav-panel {
      position: relative;
    }
    .mat-tab-nav-bar {
      margin-bottom: 1.35rem;
      border-bottom-color: mat.get-color-from-palette($primary, 600);

      .mat-tab-link {
        @include mat.typography-level($zonar-default-typography, body-2);
        color: mat.get-color-from-palette($primary, darker);
        text-decoration: none;
        opacity: 1;
      }

      .mat-tab-link:hover {
        background-color: mat.get-color-from-palette($accent, hover);
        color: mat.get-color-from-palette($primary, darker);
        border-bottom-width: 0.125rem;
        border-bottom-color: mat.get-color-from-palette($foreground, base);
        border-bottom-style: solid;
      }

      .mat-tab-label-active {
        color: mat.get-color-from-palette($foreground, base);
        opacity: 1;
      }

      .mat-ink-bar {
        background-color: mat.get-color-from-palette($accent);
      }
    }
  }
}