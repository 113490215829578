@use '@angular/material' as mat;

@mixin summary-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .filter-summary-container {
    // padding-bottom: 0;
    min-height: 8rem;

    .fleet-filter {
      .grid-title {
        margin-top: 0.5rem;

        .summary-title {
          color: mat.get-color-from-palette($primary, darker);
          @include mat.typography-level($zonar-default-typography, caption);
        }
      }

      .grid-value {
        margin-top: 5px;

        .summary-value {
          color: mat.get-color-from-palette($primary, darker);
          @include mat.typography-level($zonar-default-typography, subheading-1);
          font-size: 1.25rem;
          white-space: pre;
        }
      }
    }

    .fleet-summary {
      margin-top: 1.5rem;

      .grid-title {

        .summary-title {
          color: mat.get-color-from-palette($primary, darker);
          @include mat.typography-level($zonar-default-typography, caption);

          .mat-grid-tile-content {
            align-items: flex-end;
          }
        }
      }

      .grid-value {
        .summary-value {
          color: mat.get-color-from-palette($primary, darker);
          @include mat.typography-level($zonar-default-typography, caption);
        }
      }
    }

    .mobile-summary {
      display: flex;

      .mobile-fleet-filter {
        width: 60%;
        padding-right: 0.5rem;

        ul {
          padding-inline-start: unset;

          .summary-filter-item {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            height: auto;

            .mobile-summary-title {
              color: mat.get-color-from-palette($primary, darker);
              @include mat.typography-level($zonar-default-typography, body-1);

            }

            .mobile-summary-value {
              margin-bottom: 1rem;
              color: mat.get-color-from-palette($primary, darker);
              @include mat.typography-level($zonar-default-typography, subheading-1);
              font-size: 1.25rem;
            }
          }
        }
      }

      .mobile-fleet-summary {
        width: 40%;
        ul {
          padding-inline-start: unset;
          .mobile-summary-title {
            list-style-type: none;
            color: mat.get-color-from-palette($primary, darker);
            @include mat.typography-level($zonar-default-typography, body-1);
            height: auto;
            margin-bottom: 1.75rem;
          }

          .mobile-summary-value {
            margin-bottom: 1rem;
            list-style-type: none;
            color: mat.get-color-from-palette($primary, darker);
            @include mat.typography-level($zonar-default-typography, body-1);
            font-size: 1.25rem;
          }
        }
      }
    }

    .summary-dialog {
      white-space: pre-line;

      h2 {
        margin-right: 4rem;
        @include mat.typography-level($zonar-default-typography, subheading-2);
        &.mobile-h2 {
          margin-right: 0;
        }
      }

      h4 {
        font-size: 1.25rem;
        font-weight: 400;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 1.313rem;
      }

      .disclaimer {
        margin-right: 6rem;
        padding-top: 2.125rem;
        
        font-weight: unset;

        &.mobile-disclaimer {
          margin-right: 0;
        }
        
        span {
          @include mat.typography-level($zonar-default-typography, caption);
        }
      }
    }

    .edit-icon {
      cursor: pointer;
      margin-left: 0.125rem;
      color: mat.get-color-from-palette($accent);
    }

    .info-icon {
      color: mat.get-color-from-palette($accent);
    }
  }
}
