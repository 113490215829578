@use '@angular/material' as mat;

@mixin utilization-mobile-cards-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mobile-utilization-cards-container {
    .basic-card-content {
      padding-top: 16px;
  
      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 0px;
  
        .cell {
          display: flex;
          gap: 0.4rem;
  
          &.title {
            font-weight: 700;
          }
  
          &.value.icon {
            color: mat.get-color-from-palette($accent, 'default');
            text-decoration: underline;
          }

          .text-algin-right {
            text-align: right;
          }

          &.icon-cursor {
            img, mat-icon {
              cursor: pointer;
            }
          }
        }
  
        mat-icon {
          color: mat.get-color-from-palette($accent, 'default');
        }
      }
  
      .seperator {
        height: 1px;
        background: #e2e4e6;
      }
    }

    app-legend-list {
      .table-legend {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        margin: 0.8rem 0rem;

        .legend-item {
          display: flex;
          gap: 0.5rem;
        }
      }
    }
  }
}
