@use '@angular/material' as mat;

@mixin utilization-summary-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .utilization-summary-container {
    min-height: 8rem;

    mat-card-header {
      div.card-title {
        color: black;

        .date-range {
          color: mat.get-color-from-palette($primary, 700);
        }
      }

      .utilization-summary-info-dialog {
        .dialog-content-container {
          max-width: 27rem;
          padding: 1.5rem;
          border-radius: 0.188rem;
          color: mat.get-color-from-palette($primary, 900);

          .title {
            font-size: 1.75rem;
            margin-bottom: 0.625rem;
          }

          .description {
            margin-bottom: 1.938rem;

            a {
              text-decoration: none;
            }
          }

          .informational-text {
            .text-title {
              font-size: 1.25rem;
            }

            .text-value {
              margin-bottom: 1.938rem;
            }
          }

          button {
            font-size: 1rem;
          }
        }
      }
    }

    mat-card-content {
      color: mat.get-color-from-palette($primary, darker);

      .summary-info {
        .summary-title {
          @include mat.typography-level($zonar-default-typography, caption);
        }

        .summary-value {
          @include mat.typography-level($zonar-default-typography, subheading-1);
          font-size: 1.25rem;
          white-space: pre;
        }
      }

      .mobile-summary-info {
        ul {
          padding-inline-start: unset;
          margin: 0;

          li {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            height: auto;
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
            }

            .summary-title {
              @include mat.typography-level($zonar-default-typography, body-1);
            }

            .summary-value {
              @include mat.typography-level($zonar-default-typography, subheading-1);
              font-size: 1.25rem;
            }
          }
        }
      }

      ngx-skeleton-loader.loading-container span.skeleton-loader {
        height: 3.5rem;
      }

      ngx-skeleton-loader.mobile-loading-container span.skeleton-loader {
        height: 19.5rem;
      }
    }
  }
}
