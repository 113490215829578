@use '@angular/material' as mat;


@mixin settings-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .settings-container {
    text-decoration: none;
    color: mat.get-color-from-palette($accent, default);
    display: flex;
    padding: 0.5rem;
  
    .text {
      @include mat.typography-level($zonar-default-typography, body-2);
      font-size: 1rem;
      font-weight: 700;
      margin-left: 0.25rem;
    }
  }
}