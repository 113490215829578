@use "@angular/material" as mat;
@import "@zonar-ui/material/zonar-ui-material.theme";
@import "@zonar-ui/core/zonar-ui-core.theme";
@import "@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme";
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '@zonar-ui/table/zonar-ui-table.theme';
@import '@zonar-ui/filter/zonar-ui-filter.theme';
@import '@zonar-ui/date-picker/zonar-ui-date-picker.theme';
@import '@zonar-ui/asset-properties/zonar-ui-asset-properties.theme';
@import '@zonar-ui/chart/zonar-ui-chart.theme.scss';
@import '@zonar-ui/mobile-cards/zonar-ui-mobile-cards.theme';

@import '../app/containers/overview-container/overview-container.theme';
@import '../app/containers/status-container/status-container.theme';
@import '../app/containers/unused-assets-container/unused-assets-container.theme';
@import '../app/components/page-title/page-title.theme';
@import '../app/components/no-records/no-records.theme';
@import '../app/components//mobile-drawer/mobile-drawer.component.theme';
@import '../app/components/dialog//driver-info-dialog/driver-info-dialog.theme';
@import 'node_modules/@zonar-ui/zonar-ui-map/zonar-ui-map.theme';
@import './common-table.theme.scss';

@mixin apply-zonar-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-table-theme($theme);
  @include zonar-ui-asset-properties-theme($theme);
  @include zonar-ui-mobile-cards-theme($theme);
  @include zonar-ui-filter-theme($theme);
  @include zonar-ui-date-picker-theme($theme);
  @include zonar-ui-map-theme($theme);
  @include zonar-ui-chart-theme($theme);

  @include page-title-theme($theme);
  @include overview-theme($theme);
  @include unused-assets-container-theme($theme);
  @include no-records-theme($theme);
  @include mobile-drawer-theme($theme);
  @include common-table-theme($theme);
  @include driver-info-theme($theme);

  .primary-container {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }

  .mat-sidenav-content,
  .content-container {
    background-color: mat.get-color-from-palette($primary, 200);
  }

  // raised button color
  .mat-flat-button,
  .mat-raised-button {
    background-color: mat.get-color-from-palette($background, flat-button);
    color: mat.get-color-from-palette($foreground, text-flat-button);
  }

  zui-asset-properties-filter {
    .mat-form-field-wrapper {
      padding-bottom: 0px;
    }
  }

  // TODO: temporary change, will be removed in future.
  .asset-filter .ui-tree-dropdown-form {
    width: 12.75rem;
    max-width: unset;
  }

  .cdk-overlay-pane {
    .button-ml {
      background-color: mat.get-color-from-palette($accent, default);
      color: mat.get-color-from-palette($accent, default-contrast);
    }

    .cancel-button-ml {
      color: mat.get-color-from-palette($accent);
    }
  }
}


.zonar-default-theme{
  @include apply-zonar-theme($zonar-default-theme);
}

.zonar-dark-theme{
  @include apply-zonar-theme($zonar-default-theme);
}

// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
  .mat-option-text {
    font-size: medium;
  }

  .mat-select-panel {
    max-height: fit-content;
  }

  .burn-rate,
  .fuel-cost {
    line-height: 1.5rem;
    width: 5.475rem;
    margin-left: 0.816rem;
    margin-right: 0.66rem;

    .mat-form-field-infix {
      padding: 0 0 0.5rem 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-prefix {
      top: 0;
    }

    .mat-form-field-subscript-wrapper {
      margin-top: 1.4rem;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  .fuel-cost-dialog {
    .title,
    .title-mobile {
      @include mat.typography-level($zonar-default-typography, body-1);
      font-size: 1.25rem;
    }

    .title-mobile {
      margin-top: 7rem;
    }

    .fuel-cost-row,
    .burn-rate-row {
      width: 100%;
    }

    .burn-rate-row {
      margin-top: 1rem;
    }

    .mat-error {
      font-size: 0.75rem;
      width: 100%;
      text-align: center
    }

    .extra-padding {
      margin-bottom: 1.031rem;
    }
  }

}

.data-point-dropdown-panel {
  margin-top: 2.2rem;
}

.filter-bar-wrapper {
  margin-bottom: 1rem;
  .custom-dropdown-class {
    max-width: 170px;
  }
}