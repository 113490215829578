@use '@angular/material' as mat;

@mixin top-idlers-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .top-idlers {
    height: 18.815rem;
    .desktop-width { 
      width: 25.375rem;
    }

    .mobile-width { 
      width: 100%;
    }

    .top-idlers-table-container {
      height: 16.815rem;
      overflow: auto;
      margin-top: -1rem;

      table {
        width: 100%;
      }

      .skeleton-loader {
        width: 80%;
      }

      .mat-table {
       .mat-header-cell {
          text-transform: uppercase;
          text-align: left;
        }
        .mat-sort-header-content {
          text-transform: uppercase;
          text-align: left;
        }
  
        .mat-cell {
          font-size: mat.font-size($zonar-default-typography, 'body-2');
          font-weight: 400;
          font-size: 1rem;
          white-space: unset;
          word-wrap: unset;
          padding: 0px;
        }
  
        table.mat-table {
            flex: 1;
            background-color: mat.get-color-from-palette($accent, 100);
        }
        td.mat-cell,
        td.mat-footer-cell {
            border-bottom-width: 1px;
            border-bottom-color: mat.get-color-from-palette($primary, 300);
        }
        table.mat-header-row,
        th.mat-header-cell {
          border-bottom-width: 2px;
          border-bottom-color: mat.get-color-from-palette($primary, 300);
        }

        .mat-column-index {
          font-weight: 700;
          padding-right: 6px;
          flex: 0 0 3%;
          width: 3%;
        }
        .mat-column-assetName {
          font-weight: 700;
          padding-right: 6px;
          flex: 0 0 40%;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
        }
        .mat-column-avgHours {
          flex: 0 0 48%;
          width: 48%;
          padding-right: 0px;
        }
      }
    }

    .noresult-container {
      position: absolute;
      top: 7.5rem;
    }
  }
  
}
