@use '@angular/material' as mat;

@mixin mobile-card-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mobile-card-container {
    @include mat.elevation(3);
    margin-bottom: 1em;
    padding: 0;
    
    .mat-card-content {
      @include mat.typography-level($zonar-default-typography, body-1);
      font-size: 1rem;
      padding: 0 1.25em 1.25em 1.25em;
      

      .top-section {
        margin-bottom: 1rem;
        flex-direction: row;
        display: flex;
        place-content: center space-between;
      }
      .title-container {
        display: flex;
        align-items: center;
        .mat-icon {
          color: mat.get-color-from-palette($primary, 600);
          width: 1.25rem;
          height: 1rem;
          font-size: 1rem;

          &.marker-icon {
            padding: 0.5rem;
            width: 1rem;
            margin-left: -1.25rem;
            margin-right: 0.75rem;
            align-self: start;
            color: mat.get-color-from-palette($primary, 100);
            border-radius: 0.1875rem 0rem;

            &.lens {
              background-color: mat.get-color-from-palette($foreground, icon-pass);
            }

            &.error {
              background-color: mat.get-color-from-palette($warn, default);
            }
          }
        }
        .title {
          @include mat.typography-level($zonar-default-typography, body-1);
          color: mat.get-color-from-palette($primary, darker);
          font-size: 0.75rem;
          margin-right: 0.5rem;
        }
      }
      .expand-icon {
        padding-top: 1.25rem;
        cursor: pointer;
      }

      .summary-content {
        @include mat.typography-level($zonar-default-typography, body-1);

        .label {
          font-weight: 400;
        }
        .value {
          font-weight: 600;
          font-size: 1rem;
        }
        .has-margin {
          margin-left: 0.5rem;
        }
        .link {
          color: mat.get-color-from-palette($accent, 'default');
          text-decoration-line: underline;
          font-weight: 600;
        }
        .separator {
          font-weight: 600;
          font-size: 1rem;
        }
      }

      .mat-expansion-panel {
        @include mat.elevation(0);

        .mat-expansion-panel-body {
          padding: 1.5em 0 0;

          .expand-item {
            border-top: 0.063rem solid mat.get-color-from-palette($primary, lighter);
            padding: 0.75em 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0;

            .label {
              text-transform: uppercase;
              color: mat.get-color-from-palette($primary, darker);
            }

            .value {
              font-weight: bold;
            }
            .link {
              color: mat.get-color-from-palette($accent, 'default');
              text-decoration-line: underline;
              // font-weight: 600;
            }
            &.issue-details {
              .value {
                .key {
                  text-transform: uppercase;
                }
              }
            }
          }

          .separator {
            height: 0.125rem;
            background: mat.get-color-from-palette($zonar-gray, 100);
            width: 100%;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
}
