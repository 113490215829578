@use '@angular/material' as mat;

@mixin card-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .card-container {
    .mat-card-header {
      .date-range{
        margin-left: 1.125rem;
        @include mat.typography-level($zonar-default-typography, title);
        color: mat.get-color-from-palette($primary, darker);
        font-size: 1rem;
      }
      .mat-card-header-text {
        margin: 0;

        .mat-card-title {
          @include mat.typography-level($zonar-default-typography, subheading-2);
        }

        .mat-card-action {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .card-content {
      height: 100%;
    }

  }
}