@use '@angular/material' as mat;

@import 'src/app/components/mobile-cards/mobile-card/mobile-card.theme';

@mixin mobile-cards-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mobile-cards-container {
    @include mobile-card-theme($theme);

    .mobile-cards-title {
      @include mat.typography-level($zonar-default-typography, subheading-2);
      font-size: 1.25rem;
    }

    .mobile-cards-skeleton-container {
      .mat-card {
        margin-bottom: 1em;
      }
    }
  }
}