@use '@angular/material' as mat;

@mixin utilization-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .utilization-table-container {
    .mat-table {
      .text-overflow {
        white-space: pre-wrap;
      }
    }
    
    .table-legend {
      display: flex;
      gap: 2.7rem;
      align-items: center;
      min-height: 3.5rem;
      margin-top: -3.5rem;
      margin-left: 0.95rem;
      padding-right: 50%;

      .legend-item {
        padding-right: 0px;

        span {
          margin-left: 0.4rem;
        }
      }
    }

    .utilization-cell {
      .icon {
        display: inline-block;
        width: 18px;
        height: 14px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        &.over {
          background-image: url('../../../assets/images/utilization_over.svg');
        }

        &.normal {
          background-image: url('../../../assets/images/utilization_normal.svg');
        }

        &.under {
          background-image: url('../../../assets/images/utilization_under.svg');
        }
      }
    }
  }
}
