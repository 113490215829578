@use '@angular/material' as mat;

@mixin driver-info-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .driver-info-dialog {
    .title {
      @include mat.typography-level($zonar-default-typography, subheading-2);
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }

    .first-paraph {
        margin-bottom: 2rem;
    }

    .close-button {
      margin-top: 1.5rem;
      align-self: self-end;
    }
  }
}