@use '@angular/material' as mat;

@mixin utilization-trend-chart-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .utilization-trend-chart-container {
    .zui-line-chart-container {
      width: 100%;
      height: 317px;

      > div:not(.title-wrapper) {
        height: 100% !important;
      }
    }

    .category-tooltip-container {
      font-family: 'Open Sans';
      display: flex;
      flex-direction: column;
      gap: 6px;
      color: mat.get-color-from-palette($primary, darker);

      .categories {
        .icon {
          display: inline-block;
          width: 18px;
          height: 14px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
        }
        
        .under .icon{
          background-image: url('../../../assets/images/utilization_under.svg');
        }

        .nomal .icon{
          background-image: url('../../../assets/images/utilization_normal.svg');
        }

        .over .icon{
          background-image: url('../../../assets/images/utilization_over.svg');
        }
        
      }
    }
  }

  @media screen and (max-width: 959px) {
    .utilization-trend-chart-container {
      .zui-line-chart-container {
        height: 467px;

        .zui-line-trend-chart[echarts] {
          height: 100% !important;
        }
      }
    }
  }
}
