@use '@angular/material' as mat;

@mixin utilization-pie-chart-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .pie-chart-container {
    min-width: 17.5rem;
    height: 19.813rem;

    .mat-card-header {
      div.mat-card-header-text {
        .mat-card-title {
          margin-bottom: 0;
        }
      }

      div.mat-card-action {
        align-self: flex-start;

        .info-icon {
          color: mat.get-color-from-palette($accent);
          cursor: pointer;
        }
      }
    }

    .mat-card-content {
      height: 17.406rem;
    }

    div.pie-chart-tooltip-container {
      @include mat.typography-level($zonar-default-typography, caption);
      color: mat.get-color-from-palette($primary, darker);
    }
  }
}
