@use '@angular/material' as mat;

@mixin asset-driver-info-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .driver-name {
    margin-right: 0.25rem;
  }

  .driver-info.info-icon {
    color: mat.get-color-from-palette($accent);
    cursor: default;
  }
}
