@use '@angular/material' as mat;

@mixin idle-event-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .idle-event-table-container {
    @include csv-export-button-theme($theme);

    .mat-table {
      .mat-column-address {
        min-width: 10rem;

        div {
          width: 100%;
        }

        .address-cell {
          cursor: pointer;
          color: mat.get-color-from-palette($accent, 'default');
          text-decoration: underline;
        }
      }

      .mat-column-idleCost,
      .mat-column-idleFuel {
        justify-content: right;

        span {
          margin-right: 1.125rem;
        }

        .mat-sort-header-content {
          text-align: right;
        }
      }

      .mat-column-severity {
        max-width: 4.5rem;
      }

      .mat-column-beginTs,
      .mat-column-endTs {
        max-width: 8.25rem;
      }

      .mat-column-manufacturer {
        min-width: 8.25rem;
      }

    }
  }
}