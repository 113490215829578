@use '@angular/material' as mat;

@mixin idle-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .idle-table-container {
    @include csv-export-button-theme($theme);

    .mat-table {
      .mat-column-severity {
        max-width: 4.5rem;
      }

      td.mat-column-location {
        color: mat.get-color-from-palette($accent, default);
        text-decoration-line: underline;
      }

      .mat-column-latestDate,
      .mat-column-location {
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: unset;
        }
      }

      .mat-column-idleCost,
      .mat-column-idleFuel {
        justify-content: right;

        span {
          margin-right: 1.125rem;
        }

        .mat-sort-header-content {
          text-align: right;
        }
      }

      .mat-column-totalEvents {
        justify-content: right;

        .total-events-text {
          margin-right: 1.125rem;
        }

        .mat-sort-header-content {
          text-align: right;
        }
      }
    }
  }
}