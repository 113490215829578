@use '@angular/material' as mat;

@mixin utilization-info-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .utilization-info-dialog {
    max-width: 27rem;
    color: mat.get-color-from-palette($primary, 900);

    .title {
      font-size: 1.75rem;
      margin-bottom: 0.625rem;
    }
  
    .description {
      margin-bottom: 1.938rem;
  
      a {
        color: mat.get-color-from-palette($accent);
        text-decoration: none;
      }
    }
  
    .informational-text {
      .text-title {
        font-size: 1.25rem;
      }
  
      .text-value {
        margin-bottom: 1.938rem;
      }
    }
    .dialog-button {
      width: 100%;

      .close-button {
        font-size: 1rem;
      }
    }
  }
}
